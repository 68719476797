import React from "react"
import { Link } from "gatsby"
import { shuffleArray } from "../utils/utils"
import Layout from "../components/layout"
import TheaterVideo from "@components/Theater/TheaterVideo"
import ImageMeta from "../components/ImageMeta"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import SEO from "../components/seo"
import ImageSlider from "../components/ImageSlider/ImageSlider"

const videos = [
  {
    youtube: "jQ6-Y4-r78E",
    thumbnail: "SDOS/DEV/2024-surfing-jQ6-Y4-r78E",
    name: "Daniel",
    description: ""
  },
  {
    youtube: "Y_xUK-XGfH8",
    thumbnail: "SDOS/DEV/2024-surfing-Y_xUK-XGfH8",
    name: "Allen",
    description: ""
  },
  {
    youtube: "0b6FMnRkIqg",
    thumbnail: "SDOS/DEV/2024-surfing-0b6FMnRkIqg",
    name: "James",
    description: ""
  },
  {
    youtube: "kEL2cNJxxzM",
    thumbnail: "SDOS/DEV/2024-surfing-kEL2cNJxxzM",
    name: "Will",
    description: ""
  }
]

// shuffleArray(videos)

const SurfProgramPage = () => {
  let language = "en"

  return (
    <SharedStateProvider>
      <Layout pageTitle="procedure-page" language={language}>
        <SEO
          lang="en"
          title="Surfing For Smiles | Oral Surgery & Dental Implant Specialists of San Diego"
          description="Help us raise money for Outdoor Outreach by viewing our top 5 favorite videos. We are donating $1 per view."
        />
        <div className="surf">
          <div className="surf__hero">
            <img
              src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/Programs/2024-surfing-for-smiles_submission"
              alt="surfing program banner"
            />
          </div>
          <div className="surf__section">
            <div className="surf__container">
              <h1>Catch Waves, Give Back</h1>
              <p>
                We’re partnering with Outdoor Outreach to connect San Diego
                youth with the great outdoors. Show your support by
                participating in our Surfing For Smiles program! We’re inviting
                San Diego area residents of all ages to submit a video of
                themselves surfing and enjoying the summer sun.
              </p>
              <p>
                Then, watch your favorite video as many times as you want. The
                video with the most views will get a surfboard, courtesy of San
                Diego’s{" "}
                <a
                  href="https://clairemontsurf.com/"
                  target="_blank"
                  title="Learn morer about Clairemont Surf"
                >
                  Clairemont Surf!
                </a>
              </p>
              <p>
                Feel good about participating. The Oral Surgery Specialists of
                San Diego is donating $1 per video view up to $2,500 to Outdoor
                Outreach. By participating in Surfing For Smiles, you make a
                difference in the lives of San Diego youth.
              </p>

              <div className="surf__divider" />

              <div className="surf__how-to">
                <h3>The Details</h3>
                <p className="surf__how-to-list">
                  <span>1</span>Submit your surfing video now through August 15,
                  2024.
                </p>
                <p className="surf__how-to-list">
                  <span>2</span>From August 20 to August 26, 2024, view your
                  favorite video as many times as you like.
                </p>
                <p className="surf__how-to-list">
                  <span>3</span>The person who submitted the video with the most
                  views will receive a surfboard from San Diego’s Clairemont
                  Surf!
                </p>
              </div>

              <div className="surf__divider" />

              <p>We look forward to seeing all of your awesome videos!</p>

              <p>
                Stay tuned to our{" "}
                <a
                  href="https://www.facebook.com/SanDiegoOralSurgery/"
                  title="Follow us on Facebook"
                  target="_blank"
                >
                  Facebook
                </a>{" "}
                and{" "}
                <a
                  href="https://www.instagram.com/sandiegooralsurgery/"
                  title="Follow us on Instagram"
                  target="_blank"
                >
                  Instagram
                </a>{" "}
                for more information!
              </p>
            </div>

            <div className="surf__outreach">
              <img
                src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/SDOS/DEV/SurfingForSmiles-logo-OutdoorOutreach-2x"
                alt="surfing program banner"
              />
              <p>
                Since 1999, Outdoor Outreach has connected 17,000 young people
                with opportunities to share new experiences and build a support
                system of peers and mentors who believe in them. This incredible
                organization connects our youth with their world to inspire,
                explore, and show that <b>they matter</b>.
              </p>
              <a
                href="https://donate.outdooroutreach.org/give/100990/%23!/donation/checkout&sa=D&source=docs&ust=1652483738900776&usg=AOvVaw3A5JFvhRlPnIFJK1OSIRfa"
                title="Make a donation"
                target="_blank"
              >
                You can make a donation directly to Outdoor Outreach here.
              </a>
            </div>
          </div>
        </div>
        {/* <div className="surf__section surf__slider">
          <div className="surf__container">
            <ImageSlider
              useDots
              adaptiveHeight
              images={[
                "SDOS/DEV/surfing-announcement-2022-1",
                "SDOS/DEV/surfing-announcement-2022-2",
                "SDOS/DEV/surfing-announcement-2022-3",
                "SDOS/DEV/surfing-announcement-2022-4",
                "SDOS/DEV/surfing-announcement-2022-5"
              ]}
            />
          </div>
        </div>*/}

        {/* <div className="surf__section color-back">
          <div className="surf__container">
            <div className="surf__video-list">
              {videos.map(vid => (
                <div className="surf__video-card" key={vid.youtube}>
                  <TheaterVideo
                    videoUrl={`https://www.youtube.com/watch?v=${vid.youtube}`}
                    controls
                    playing
                    overVideo
                    hasWatchVideo
                    iconOnly
                  >
                    <ImageMeta
                      cloudName="nuvolum"
                      publicId={vid.thumbnail}
                      width="auto"
                      responsive
                      responsiveUseBreakpoints="true"
                    />
                  </TheaterVideo>
                  <div className="surf__video-card--content">
                    <h5>{vid.name}</h5>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div> */}
        {/* <div className="surf__form">
          <div class="surf__container has-text-centered">
            <h5 style={{ marginTop: 0, marginBottom: 0 }}>
              Thank you for your interest in our Surfing for Smiles program. Our
              2024 application is now closed. Stay tuned for the voting period!
            </h5>
          </div>
        </div> */}
        <div className="surf__section">
          <div className="surf__container has-text-centered">
            <p>
              View the official{" "}
              <Link to="/surfing-terms-conditions/">Terms & Conditions.</Link>
            </p>
          </div>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export default SurfProgramPage
